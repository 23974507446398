<template>
  <div class="h5ListPage">
    <template v-if="listData.length > 0">
      <div class="list">
        <template v-for="(item, idx) in listData">
          <BaseList :item="item" :key="idx" @toPage="toPage" />
        </template>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </template>
    <div v-else class="not-data">暂无数据</div>
  </div>
</template>

<script>
import BaseList from "../components/baseList";

export default {
  components: {
    BaseList,
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 12,
      current: 5,
      total: 0,
      listData: [],
      // listData: [
      //   { title: "关于个人房屋按揭贷款LPR批量转换的", time: "05-01" },
      //   { title: "维护工作将于北京时间2020年9月12日", time: "05-01" },
      //   { title: "“金融知识普及月 金融知识进万家 争做", time: "05-01" },
      //   { title: "为更好地服务北京市工会会员，为广大", time: "05-01" },
      // ],
    };
  },
  methods: {
    init() {
      this.getList(this.$route.query.sid);
    },
    async getList(id) {
      let res = await this.$api.second.articleList({
        id: id,
        pageNo: this.pageNo,
      });
      if (res.status == 200) {
        res.data.records.forEach((item) => {
          item.createTime = item.createTime.substr(5, 6);
        });
        this.listData = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
        this.current = res.data.current;
      }
    },
    toPage(item) {
      this.$router.push({
        path: "/detail",
        query: { type: 1, sid: this.$route.query.sid, articleId: item.id },
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList(this.$route.query.sid);
    },
  },
};
</script>

<style lang="scss">
.not-data {
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}
.h5ListPage {
  width: 100%;
  float: left;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  .list {
    width: 100%;
    float: left;
  }
  .el-pagination {
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 40px;
    .btn-prev,
    .btn-next,
    .el-pager li {
      background: transparent;
    }
    .el-pager li {
      padding: 0;
      width: 20px;
      min-width: 20px;
      font-weight: normal;
      &.active {
        color: #c01515;
        font-weight: bold;
      }
    }
  }
}
</style>
